import { mergeLWW } from "./lww.js";
import { mergeGrowSet } from "./gset.js";
// July 2024 - Samsung Internet does not support Set.prototype.intersection or Set.prototype.difference
import "core-js/actual/set";
export function mergeMap({ local, remote, ignore, merge = mergeLWW, }) {
    const localIds = new Set(Object.keys(local.values));
    const remoteIds = new Set(Object.keys(remote.values));
    const localOnlyIds = localIds.difference(remoteIds);
    const remoteOnlyIds = remoteIds.difference(localIds);
    const sharedIds = [...localIds.intersection(remoteIds)];
    const mergeResults = sharedIds.map((id) => merge({
        ignore: ignore,
        local: local.values[id],
        remote: remote.values[id],
    }));
    // Deleted Set
    const deleted = mergeGrowSet({
        local: local.deleted,
        remote: remote.deleted,
    });
    const localOutcome = {
        create: [...remoteOnlyIds],
        remove: deleted.remoteOnly,
        update: mergeResults
            .filter((result) => result.changed.length > 0)
            .map((result) => result.resolved.id),
    };
    const remoteOutcome = {
        create: [...localOnlyIds],
        remove: deleted.localOnly,
        update: mergeResults
            .filter((result) => result.rejected.length > 0)
            .map((result) => result.resolved.id),
    };
    const localOnlyValues = Object.fromEntries([...localOnlyIds].map((id) => [id, local.values[id]]));
    const remoteOnlyValues = Object.fromEntries([...remoteOnlyIds].map((id) => [id, remote.values[id]]));
    const sharedValues = Object.fromEntries(mergeResults.map((result) => [result.resolved.id, result.resolved]));
    const resolved = {
        deleted: deleted.resolved,
        values: { ...localOnlyValues, ...remoteOnlyValues, ...sharedValues },
    };
    return {
        resolved,
        local: localOutcome,
        remote: remoteOutcome,
    };
}
